<script setup lang="ts">
  import { useAuthStore } from '~~/stores/auth'

  const route = useRoute()

  const yearCurrent = new Date().getFullYear()
  const config = useRuntimeConfig()
  const companyName = computed<string>(() => config.public.app.name)

  const { isAuthenticated } = storeToRefs(useAuthStore())

  watchEffect(() => {
    if (isAuthenticated.value && route.path !== '/auth/sign-verify') {
      return navigateTo((route.query.redirect as string) || '/')
    }
  })
</script>

<template>
  <div class="relative">
    <div
      class="absolute inset-0 z-0 bg-[url('/images/station-bg.jpeg')] bg-cover bg-no-repeat"
    />

    <div
      class="absolute inset-0 z-0 bg-gradient-to-b from-red-600/95 to-amber-500/90"
    />

    <div
      class="container relative z-10 mx-auto flex min-h-screen grow flex-col"
    >
      <header>
        <nav
          class="flex items-center justify-end px-3 py-5 sm:px-5 lg:px-16 xl:px-20"
        >
          <LocaleSwitchSimple class="flex items-center gap-5" />
        </nav>
      </header>

      <main class="my-auto">
        <div
          class="grid grid-cols-1 gap-y-20 py-10 sm:py-20 lg:grid-cols-2 xl:grid-cols-3"
        >
          <div
            class="flex grow items-center justify-center px-4 sm:px-5 lg:px-10 xl:px-20"
          >
            <div class="text-center">
              <LogoFull class="mx-auto h-16 w-auto shrink-0 text-white" />

              <div class="my-5 text-lg uppercase text-dark-50">
                {{ $t('app.web-portal') }}
              </div>
            </div>
          </div>

          <div
            class="flex items-center justify-center px-4 sm:px-5 lg:border-s lg:border-primary-100/20 lg:px-10 xl:col-span-2 xl:px-20"
          >
            <slot />
          </div>
        </div>
      </main>

      <footer>
        <div class="container mx-auto px-5 lg:px-10">
          <div class="border-t border-primary-100/20 py-5 text-primary-50">
            <div class="text-center text-sm">
              <span>{{ companyName }} © {{ yearCurrent }}</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
